import { Controller } from "@hotwired/stimulus";
import debounce from "lodash.debounce";
class src_default extends Controller {
  initialize() {
    this.save = this.save.bind(this);
  }
  connect() {
    if (this.delayValue > 0) {
      this.save = debounce(this.save, this.delayValue);
    }
  }
  save() {
    if (window._rails_loaded) {
      Rails.fire(this.element, "submit");
    } else {
      this.element.requestSubmit();
    }
  }
}
src_default.values = {
  delay: Number
};
export { src_default as default };
